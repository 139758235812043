
import { useState, useEffect } from 'react';
import useCollapse from "react-collapsed";
import { KeyboardArrowUp, KeyboardArrowDown, QrCode } from '@styled-icons/material-outlined/'

function InstructionItem ({ blok })  {

    const [ open, setOpen ] = useState(false);
    const { getToggleProps, getCollapseProps } = useCollapse({
        open, duration: 600
      });

      

    return(<div className="it-container">
        <div className="flex flex-row mb-1"   {...getToggleProps({
                    onClick: () => { setOpen(!open) }
                })}>
            <div className="mr-2 w-[24px]" style={{ marginTop: '-3px'}}>{ open ? <KeyboardArrowUp size="20"/> : <KeyboardArrowDown size="18"/>}</div>
            <div className="system text-lg">{ blok.system || ""}</div>
        </div>
        <div {...getCollapseProps({})}>
            <div className="pb-3">
            { blok.items.map(({ text }, i) => (
                     <>{ i + 1 }. {text}<br/></>
            ))}
            </div>
        </div>
        </div>)
}

export default InstructionItem;