import { useState } from 'react';
import { Expand } from '@styled-icons/boxicons-regular';
import {  Cross  } from '@styled-icons/icomoon'
import Modal from 'react-modal';

console.log(Modal.propTypes);

const RenderingCard = ({ blok }) => {

    const [ modalOpen, setModalOpen ] = useState(false);

  return (<div class="container max-w-[95vw] md:max-w-[390px] h-[516px]">
      {blok.youtube && blok.youtube.url ? <iframe
            style={{ maxWidth: '90vw' }}
            width="370"
            height="200"
            src={`https://www.youtube.com/embed/${blok.youtube.url.split("https://youtu.be/").pop()}`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
    /> : 
        <div onClick={() => setModalOpen(true)} className="cursor-pointer">
        <img width="370" height="200" src={blok?.image?.filename || ""} className="h-[199px] object-cover" />
        <div className="flex flex-row justify-end">
            <div className="h-[20px]">
                <Expand size="25" className="expand-icon" />
            </div>
        </div>
        </div>
    }
      <div className="name pt-3">{ blok.title }</div>
      <div className="client" >{ blok.client || ""}</div>
      <div className="description" > { blok.description }</div>

      <Modal
                isOpen={modalOpen}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => {
                    alert("a"); setModalOpen(false);
                }} 
                style={customStyles}
                ariaHideApp={false}
                contentLabel=""
                classNames={{
                    overlay: "customOverlay",
                    modal: "customModal",
                  }}
                
      >
          <div 
            className="absolute w-full h-full top-0 left-0 z-[-1]"
            onClick={() => setModalOpen(false)}></div>
       <img 
        src={blok?.image?.filename || ""} 
        className="max-w-[85vw] max-h-[85vh]" />
       {/* <Cross size="12" className="cursor-pointer absolute top-0 right-0 mt-5 mr-5"
             onClick={() => setModalOpen(false)}/> */}
        </Modal>

    </div>);
};
 
export default RenderingCard;


const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      height: '100%',
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',

    },
  };