import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

const Grid = ({ blok }) => {
  return (
    <div  {...storyblokEditable(blok)} 
      className="flex justify-around lg:justify-start flex-col lg:flex-row items-center lg:items-start"
    >
      {blok.columns.map((blok) => (
        <StoryblokComponent blok={blok} key={blok._uid} />
      ))}
    </div>
  );
};
 
export default Grid;