
import { storyblokEditable } from "@storyblok/react";

import Button from "./Button";
import { useAuth0 } from "@auth0/auth0-react";

const Navbar = ({ blok }) => {

  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();

  return <div className="mb-[127px]"><nav 
             className="absolute top-0 left-0 w-screen h-[127px] p-[30px] bg-white flex flex-row items-center justify-between"
             {...storyblokEditable(blok)} >
            <img className="w-[120px]" src="https://mrstudios.eu/wp-content/uploads/2022/07/MRstudios-logo.png" />
            
            
           
        </nav></div>;
};
 
export default Navbar;