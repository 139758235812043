import { StoryblokComponent, storyblokEditable } from "@storyblok/react";

function InstructionContainer ({ blok })  {
    return(<div className="container-instructions pb-[15px] mb-[60px]" {...storyblokEditable(blok)}>
     {blok.items.map((blok) => (
        <StoryblokComponent blok={blok} key={blok._uid} />
      ))}
    </div>)
}

export default InstructionContainer;