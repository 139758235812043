import { useState, useEffect } from 'react';
import useCollapse from "react-collapsed";
import { Download3, Cross, Rocket  } from '@styled-icons/icomoon'
import { KeyboardArrowUp, QrCode } from '@styled-icons/material-outlined/'

import Modal from 'react-modal';

const App = ({ blok }) => {

    const [ open, setOpen ] = useState(false);
    const { getToggleProps, getCollapseProps } = useCollapse({
        open, duration: 600
      });

    const [ modalOpen, setModalOpen ] = useState(false);

    return <div class="container max-w-[95vw] md:max-w-[390px]" >
        <div>
            <img width="370" height="200" src={blok?.image?.filename || ""}
                 className="h-[199px] object-cover"
            />
            <div className="tags" >
                { blok.tags.map(({ headline }) => <div>{ headline || "err" }</div>)}
            </div>
            <div className="name">{ blok.name }</div>
            <div className="client" >{ blok.client || ""}</div>
            <div className="description" > { blok.description }</div>
            </div>
        <div className="flex flex-row items-center">
            <div className="bttn" 
                {...getToggleProps({
                    onClick: () => { setOpen(!open) }
                })}
            > <div className="w-[18px] h-[18px] mr-2">
                { open ? (<KeyboardArrowUp size="26" className="absolute" 
                        style={{ marginLeft: "-4px", marginTop: "-4px"}} />)  
                : <Download3 size="18" /> }
                </div>
                <div>Download</div>
            </div>
            { blok.webrtc && blok.webrtc.url && 
                <a target="_blank" href={blok.webrtc.url}>
                    <div className="bttn"> <Rocket size="18" className="mr-2"/>Launch</div>
                </a>}
            { blok.qr_code && blok.qr_code.filename && <div className="ml-2 text-sm cursor-pointer" onClick={() => setModalOpen(true)}>
                <QrCode size="18" className="mr-1" style={{ marginTop: '-2px'}} />
                Show QR code
            </div>}
        </div>    
        <div {...getCollapseProps({})}>
                <div className="flex flex-col mb-[15px]">
                    { blok?.links?.map(
                        ({ system, link }) => (<a href={link.url} className="link">{ system }</a>)
                    )}
                </div>
        </div>
        <Modal
                isOpen={modalOpen}
                onRequestClose={() => setModalOpen(false)}
                style={customStyles}
                contentLabel="Example Modal"
                classNames={{
                    overlay: "customOverlay",
                    modal: "customModal",
                  }}
        >
        <Cross size="12" className="cursor-pointer absolute top-0 right-0 mt-5 mr-5"
             onClick={() => setModalOpen(false)}/>
        <div className="bg-white p-4 rounded-md border-gray-200 border-2">
            <img className="w-[300px] h-[300px]" src={ blok?.qr_code?.filename }/>
            <p className="pt-2 text-lg font-semibold tracking-wide">SCAN ME</p>
        </div>
        </Modal>
        </div>
  };
   
  export default App;


  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      height: '100%',
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',

    },
  };