
import { storyblokEditable } from "@storyblok/react";

const Headline = ({ blok }) => {
  return (<div className="text-left w-screen pl-2 lg:pl-0">
  <h1 {...storyblokEditable(blok)} >
      {blok.h1}
    </h1>
    <h2 
     {...storyblokEditable(blok)} >
      {blok.h2}
    </h2>
    </div>);
};
 
export default Headline;